

.qr {
  width: 100%;
  margin-top: 5px;
  padding-right: 13px;
  padding-left: 13px;
}
p {
  margin: 0px;
}

.qr_0 {
  transform: rotate(0deg);
}

.qr_30 {
  transform: rotate(90deg);
}

.qr_60 {
  transform: rotate(180deg);
}

.qr_90 {
  transform: rotate(270deg);
}

.container {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.bottom {
  width: 100%;
  bottom: 0px;
  position: fixed;
}

.inverted {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}


.wrapSegment {
  padding: 0px 5px 0px 5px;
}