
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

p.intro {
    padding: 0px 5px 0px 5px;
    color: #383836;
    font-size: 1.2em;
}

.overviewContainer {
    padding: 10px;
}

.hiddenLottie {
    position: absolute;
    top: 9999px;
    left: 9999px;
}

ion-item.qrDataAvailable {
    display: none;
}

ion-item.qrDataNotSet {
    display: block;
}

ion-item.enterPin ion-input {
    background: rgba(0,0,0,0.2);
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
}

ion-item.enterPin {
    margin-bottom: 20px;
}

ion-item.enterPin .inner {
    padding: 5px 0px 5px 0px;
}

ion-item.enterPin ion-button {

}

.overviewContainer p {
    font-weight: normal;
    text-align: left;
    font-size: 1.0em;
    color: #383836;
    line-height:1.35em;
}

.overviewContainer ion-card {
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
}

.overviewContainer ion-card-title {
    font-family: "Montserrat", sans-serif;
    color: #383836;
    font-size: 1.3em;
}

.overviewContainer ion-card-content p {
    padding-bottom: 10px;
    font-size: 0.9em;
}

.overviewContainer ion-card.empty {
    background: #f4f4fe;
    padding: 25px;
    text-align: center;
}

.overviewContainer ion-card.empty img {
    width: 65px;
    margin: 0px 0px 20px 0px;
}

.overviewContainer ion-card.empty ion-card-header {
    font-size: 11px;
}

.overviewContainer ion-button {
    --border-radius: 25px !important;
    font-weight: bold;
    font-size: 0.9em;
    width: 125px;
}

.overviewContainer ion-card-header div {
    position: relative;
}

.overviewContainer ion-card-header .holder img {
    position: absolute;
    right: 0px;
    top: -10px;
    width: 55px;
}

.overviewContainer ion-segment {
    background: none;
    border-radius: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: 2px solid #eee;
}

.overviewContainer ion-segment-button {

    font-size: 0.9em;
    padding-bottom: 6px;
    color: #727679;
}

.overviewContainer ion-segment-button.segment-button-checked {
    color: #383836;
    --indicator-height: none;
    --border-radius: 0px;
    font-weight: bold;
    border-bottom: 2px solid #333;
    margin-bottom: 0px;
    box-shadow: none;
}


.show-pane {
    display: block;
    padding-top: 25px;
}

.hidden-pane {
    display: none;
}
