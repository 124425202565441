@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);

ion-menu {
    --ion-background-color: var(--ion-color-primary);
    --ion-text-color: var(--ion-color-primary-contrast);
    font-family: "Montserrat", sans-serif !important;
}

ion-menu ion-item {

    margin-top:15px;

    font-family: "Montserrat", sans-serif !important;
    --font-family: "Montserrat", sans-serif !important;
    --color: #fff;
    font-weight: bold;
}

ion-menu ion-item ion-label {
    font-family: "Montserrat", sans-serif !important;
    --font-family: "Montserrat", sans-serif !important;

}

ion-menu ion-item.small {
    font-size: 0.9em;
    --color: #eee;
}

ion-menu ion-item.line ion-label {
    border-bottom: 1px solid #ccc;
    padding-bottom: 40px;
}

ion-menu ion-list.closeList {
    padding-top: 15px;
}

p.intro {
    padding: 0px 5px 0px 5px;
    color: #383836;
    font-size: 1.2em;
}

.overviewContainer {
    padding: 10px;
}

.hiddenLottie {
    position: absolute;
    top: 9999px;
    left: 9999px;
}

ion-item.qrDataAvailable {
    display: none;
}

ion-item.qrDataNotSet {
    display: block;
}

ion-item.enterPin ion-input {
    background: rgba(0,0,0,0.2);
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
}

ion-item.enterPin {
    margin-bottom: 20px;
}

ion-item.enterPin .inner {
    padding: 5px 0px 5px 0px;
}

ion-item.enterPin ion-button {

}

.overviewContainer p {
    font-weight: normal;
    text-align: left;
    font-size: 1.0em;
    color: #383836;
    line-height:1.35em;
}

.overviewContainer ion-card {
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
}

.overviewContainer ion-card-title {
    font-family: "Montserrat", sans-serif;
    color: #383836;
    font-size: 1.3em;
}

.overviewContainer ion-card-content p {
    padding-bottom: 10px;
    font-size: 0.9em;
}

.overviewContainer ion-card.empty {
    background: #f4f4fe;
    padding: 25px;
    text-align: center;
}

.overviewContainer ion-card.empty img {
    width: 65px;
    margin: 0px 0px 20px 0px;
}

.overviewContainer ion-card.empty ion-card-header {
    font-size: 11px;
}

.overviewContainer ion-button {
    --border-radius: 25px !important;
    font-weight: bold;
    font-size: 0.9em;
    width: 125px;
}

.overviewContainer ion-card-header div {
    position: relative;
}

.overviewContainer ion-card-header .holder img {
    position: absolute;
    right: 0px;
    top: -10px;
    width: 55px;
}

.overviewContainer ion-segment {
    background: none;
    border-radius: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: 2px solid #eee;
}

.overviewContainer ion-segment-button {

    font-size: 0.9em;
    padding-bottom: 6px;
    color: #727679;
}

.overviewContainer ion-segment-button.segment-button-checked {
    color: #383836;
    --indicator-height: none;
    --border-radius: 0px;
    font-weight: bold;
    border-bottom: 2px solid #333;
    margin-bottom: 0px;
    box-shadow: none;
}


.show-pane {
    display: block;
    padding-top: 25px;
}

.hidden-pane {
    display: none;
}

ion-header ion-title {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

ion-header ion-title.md {
    font-size: 19px !important;
}

ion-header ion-toolbar {
    padding-top: 0px !important;
    --border-width: 0px !important;
}

ion-header ion-toolbar a.ion-button {
    color: green !important;
}

ion-header ion-toolbar ion-menu-button {
    color: #333;
}

@media (orientation: landscape) {
    html {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh !important;
        height: 100vw !important;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
}

.container-offline {
    text-align: center;
    padding: 50px;
}

.container-offline img {
    width: 75%;
    margin-top: 50px;
}

.container-offline h1 {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 25px;
    color: #333;
}

.container-offline p {
    font-weight: normal;
    font-size: 0.9em;
    color: #333;
    line-height:1.6em;
}
ion-header ion-title {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

ion-header ion-title.md  {
    font-size: 19px !important;
}

ion-header ion-toolbar {
    padding-top: 0px !important;
    --border-width: 0px !important;
}


.qr {
  width: 100%;
  margin-top: 5px;
  padding-right: 13px;
  padding-left: 13px;
}
p {
  margin: 0px;
}

.qr_0 {
  transform: rotate(0deg);
}

.qr_30 {
  transform: rotate(90deg);
}

.qr_60 {
  transform: rotate(180deg);
}

.qr_90 {
  transform: rotate(270deg);
}

.container {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.bottom {
  width: 100%;
  bottom: 0px;
  position: fixed;
}

.inverted {
  transform: scaleX(-1);
}


.wrapSegment {
  padding: 0px 5px 0px 5px;
}
ion-header ion-title {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

ion-header ion-title.md {
    font-size: 19px !important;
}

ion-header ion-toolbar {
    padding-top: 0px !important;
    padding-right: 13px;
    padding-left: 13px;
    --border-width: 0px !important;
}

ion-header ion-button {
    font-size: 0.85em !important;
    text-align: right !important;
    margin-right: 0px !important;
    --padding-end: 0px !important;
    --color: #333;
}

ion-header ion-back-button {
    font-size: 0.9em !important;
    --color: #333;
}

.modal-class .modal-wrapper {
    height: 85%;
    bottom: 0px;
    background: #fff;
    border-radius: 15px 15px 0px 0px;
    position: absolute;
    display: block;
}

ion-button.detailsBlue {
    padding-top: 0px;
    --color: #007aff !important;
    font-size: 1em !important;
}

.modalContent {
    color: #383836;
    padding: 20px;
    font-size: 1em;
}


.modalContent h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8em;
    margin-top: 10px;
    margin-bottom: 20px;
}

.modalContent p  {
    margin-bottom: 20px;
    color: #383836;
    line-height: 1.5em;
}


:root {

  
  --ion-color-primary: #1540eb;
  --ion-color-primary-rgb: 21, 64, 235;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1238cf;
  --ion-color-primary-tint: #2c53ed;

  
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

}

