@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

ion-header ion-title {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

ion-header ion-title.md {
    font-size: 19px !important;
}

ion-header ion-toolbar {
    padding-top: 0px !important;
    padding-right: 13px;
    padding-left: 13px;
    --border-width: 0px !important;
}

ion-header ion-button {
    font-size: 0.85em !important;
    text-align: right !important;
    margin-right: 0px !important;
    --padding-end: 0px !important;
    --color: #333;
}

ion-header ion-back-button {
    font-size: 0.9em !important;
    --color: #333;
}

.modal-class .modal-wrapper {
    height: 85%;
    bottom: 0px;
    background: #fff;
    border-radius: 15px 15px 0px 0px;
    position: absolute;
    display: block;
}

ion-button.detailsBlue {
    padding-top: 0px;
    --color: #007aff !important;
    font-size: 1em !important;
}

.modalContent {
    color: #383836;
    padding: 20px;
    font-size: 1em;
}


.modalContent h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8em;
    margin-top: 10px;
    margin-bottom: 20px;
}

.modalContent p  {
    margin-bottom: 20px;
    color: #383836;
    line-height: 1.5em;
}