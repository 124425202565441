
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

ion-menu {
    --ion-background-color: var(--ion-color-primary);
    --ion-text-color: var(--ion-color-primary-contrast);
    font-family: "Montserrat", sans-serif !important;
}

ion-menu ion-item {

    margin-top:15px;

    font-family: "Montserrat", sans-serif !important;
    --font-family: "Montserrat", sans-serif !important;
    --color: #fff;
    font-weight: bold;
}

ion-menu ion-item ion-label {
    font-family: "Montserrat", sans-serif !important;
    --font-family: "Montserrat", sans-serif !important;

}

ion-menu ion-item.small {
    font-size: 0.9em;
    --color: #eee;
}

ion-menu ion-item.line ion-label {
    border-bottom: 1px solid #ccc;
    padding-bottom: 40px;
}

ion-menu ion-list.closeList {
    padding-top: 15px;
}