
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

.container-offline {
    text-align: center;
    padding: 50px;
}

.container-offline img {
    width: 75%;
    margin-top: 50px;
}

.container-offline h1 {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 25px;
    color: #333;
}

.container-offline p {
    font-weight: normal;
    font-size: 0.9em;
    color: #333;
    line-height:1.6em;
}