@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

ion-header ion-title {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

ion-header ion-title.md {
    font-size: 19px !important;
}

ion-header ion-toolbar {
    padding-top: 0px !important;
    --border-width: 0px !important;
}

ion-header ion-toolbar a.ion-button {
    color: green !important;
}

ion-header ion-toolbar ion-menu-button {
    color: #333;
}

@media (orientation: landscape) {
    html {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh !important;
        height: 100vw !important;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
}